<template>
  <div>
    <modal-exportar-dados
      v-if="exportar"
      @fechaModal="exportar = $event.value"
      :idAutorizada="autorizadaAtiva"
      tipoDownload="Treinamentos"
      :treinamentos="true"
    />

    <modal-filtro-treinamentos
      v-if="exibeModalFiltro"
      @statusBusca="statusFiltro = $event"
      @fecharModal="exibeModalFiltro = false"
    />

    <div class="btn-download-planilha">
      <button class="btn-exportar" @click="exportar = true">
        <img :src="iconesImgs.iconeDownload" class="icone" /> Exportar dados
      </button>
    </div>

    <div class="grafico">
      <div class="programa">
        <div class="filtroContainer">
          <img :src="iconesImgs.iconePrograma" />
          <h2 class="titulo">Percentual de Treinamentos Realizados</h2>
          <div class="filtro">
            <filtro-grafico
              :filtrarGrafico="(filtro) => filtroGrafico(filtro)"
              @filtraMesesPorAno="arrayGrafico.ano = $event.id"
              :mostrarTitulo="false"
              :tipo="true"
              :meses="mesesFiltro"
              :treinamentos="true"
            />
          </div>
        </div>
        <div class="big-numbers">
          <div class="box">
            <h2 class="titulo">Total Realizado</h2>
            <p class="numero">{{ infoTreinamentos.totalRealizado }}</p>
          </div>

          <div class="box">
            <h2 class="titulo">Técnicos</h2>
            <p class="numero">{{ infoTreinamentos.percentualTecnicos }}</p>
          </div>

          <div class="box">
            <h2 class="titulo">Administrativos</h2>
            <p class="numero">
              {{ infoTreinamentos.percentualAdministrativos }}
            </p>
          </div>
        </div>

        <div class="filtroContainer">
          <img :src="iconesImgs.iconePrograma" />
          <h2 class="titulo">Percentual por tipo de treinamento</h2>
        </div>

        <div class="graficos">
          <div class="grafico-circulo">
            <grafico-circulo :dados="universidadeEncantamento" />
          </div>
          <div class="grafico-circulo">
            <grafico-circulo :dados="escolaTecnica" />
          </div>
          <div class="grafico-circulo">
            <grafico-circulo :dados="horaDoAdmin" />
          </div>
        </div>

        <div class="legenda">
          <div
            class="pontuou"
            v-for="(legenda, index) in infoTreinamentos.legendas"
            :key="index"
          >
            <div
              class="cor"
              :style="{ backgroundColor: legenda.codigoCor }"
            ></div>
            <div class="texto">{{ legenda.descricao }}</div>
          </div>
          <div class="pontuou">
            <div class="cor reprovado"></div>
            <div class="texto">Reprovado / Não realizado</div>
          </div>
        </div>
      </div>

      <div class="treinamentos">
        <div class="flex">
          <img :src="iconesImgs.iconeTreinamentos" class="icone-treinamentos" />
          <h2 class="titulo">Treinamentos Realizados</h2>
          <div class="busca-treinamento desktop">
            <input
              type="text"
              class="input-buscar"
              placeholder="Pesquisar"
              v-model="arrayBusca.buscar"
            />
            <button
              v-if="!buscaFeita"
              class="btn-buscar"
              @click="buscarTreinamentoPorUsuario()"
            >
              Buscar
            </button>
            <button v-else class="btn-buscar" @click="limparBusca()">
              Limpar busca
            </button>
          </div>
        </div>
        <div class="busca-treinamento mobile">
          <div class="btn-input">
            <input
              type="text"
              class="input-buscar"
              placeholder="Pesquisar"
              v-model="arrayBusca.buscar"
            />
            <button
              v-if="!buscaFeita"
              class="btn-buscar-mobile"
              @click="buscarTreinamentoPorUsuario()"
            >
              <img
                :src="iconesImgs.iconeBusca"
                alt="Clique para buscar"
                title="Clique para buscar"
              />
            </button>
            <button v-else class="btn-buscar" @click="limparBusca()">
              Limpar busca
            </button>
          </div>
        </div>
        <div class="barra-titulo">
          <p class="status">STATUS</p>
          <p class="cpf">CPF</p>
          <p class="nome">NOME</p>
          <p class="curso">CURSO</p>
          <p class="data">
            DATA
            <img
              :src="iconesImgs.iconeFiltrar"
              alt="Botão para filtrar por status"
              class="icone-filtro"
              @click="exibeModalFiltro = true"
            />
          </p>
        </div>
        <h2 class="h2" v-if="!treinamentos.length">
          Ainda não possui treinamentos.
        </h2>
        <div
          v-for="(item, index) in treinamentos"
          :key="index"
          class="item"
          v-else
        >
          <div
            v-if="item.status === 'Aprovado'"
            style="background: linear-gradient(#50b07c 0%, #a2e323 100%)"
            class="icone"
          >
            <img :src="iconesImgs.iconeIRealizado" />
            {{ item.status }}
          </div>
          <div
            v-else-if="item.status === 'Reprovado'"
            style="background: linear-gradient(#f7941d 0%, #e64e20 100%)"
            class="icone"
          >
            <img :src="iconesImgs.iconeIPendente" />
            {{ item.status }}
          </div>
          <div v-else style="background: #bcbcbc" class="icone">
            <img :src="iconesImgs.iconeIPendente" />
            {{ item.status }}
          </div>

          <div class="texto">
            <p class="cpf">{{ formataCPF(item.cpf) }}</p>
            <p class="nome">{{ item.nome }}</p>

            <p class="curso">{{ item.curso }}</p>
            <p class="data">{{ formatarData(item.data) }}</p>
          </div>
        </div>
        <transition name="fade-left" mode="out-in">
          <div class="paginacao">
            <button :disabled="arrayBusca.pagina == 1" @click="pagAnterior()">
              Anterior
            </button>
            <p>
              {{ arrayBusca.pagina }}
              {{ totalobjetos != 0 ? " - " + totalobjetos : "" }}
            </p>
            <button @click="proxPagina()">Próxima</button>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {
  buscarGraficoTreinamento,
  buscarTreinamentoEquipe,
} from "@/api/performance";
import { downloadPlanilhaTreinamento } from "@/api/treinamentos";
import { mapState, mapActions } from "vuex";
import { formataCPF } from "@/utils/masks.js";
import { obterMesesComTreinamento } from "@/api/treinamentos";
import iconesImgs from "@/utils/icones.js";
import ModalExportarDados from "@/components/modal/ModalExportarDados.vue";
import ModalFiltroTreinamentos from "@/components/modal/ModalFiltroTreinamentos.vue";
import FiltroGrafico from "./Filtros/FiltroGraficos.vue";
import GraficoCirculo from "./Graficos/GraficoCirculo.vue";
import moment from "moment";
export default {
  components: {
    GraficoCirculo,
    FiltroGrafico,
    ModalExportarDados,
    ModalFiltroTreinamentos,
  },
  computed: {
    ...mapState({
      autorizadaAtiva: (state) => state.usuario.autorizadaAtiva,
    }),
  },
  filters: {
    // removeCasasDecimais(value) {
    //     if (value == '0%') return '0%';
    //     return value.split(",").shift() + '%';
    // }
  },
  data() {
    return {
      buscaFeita: false,
      statusFiltro: 0,
      formataCPF,
      downloadPlanilhaTreinamento,
      iconesImgs,
      exportar: false,
      graficoLista: [],
      validarLegenda: null,
      pagina: 1,
      totalobjetos: 0,
      ultimoItem: false,
      busca: "",
      exibeModalFiltro: false,
      dataJs: "",
      treinamentos: [],
      arrayGrafico: {
        autorizadaId: null,
        mes: 0,
        ano: 0,
      },
      infoTreinamentos: {
        percentualAdministrativos: "0",
        percentualTecnicos: "0",
        totalRealizado: "0",
      },
      arrayBusca: {
        pagina: 1,
        autorizadaId: "",
        buscar: null,
        status: 0,
        mes: "",
        ano: 2024,
      },
      mesesFiltro: null,
      universidadeEncantamento: [],
      escolaTecnica: [],
      horaDoAdmin: [],
    };
  },
  watch: {
    statusFiltro() {
      this.buscarPerformanceTreinamentos(1);
      this.exibeModalFiltro = false;
    },
    autorizadaAtiva() {
      this.filtroGrafico(this.arrayGrafico);
      this.buscaMesesDownload();
    },
    "arrayGrafico.ano"() {
      this.arrayGrafico.mes = 1;
      this.buscaMesesDownload();
    },
  },
  created() {
    this.dataJs = new Date();
    this.arrayGrafico.mes = this.dataJs.getMonth() + 1;
    this.arrayGrafico.ano = new Date().getFullYear();
    this.filtroGrafico(this.arrayGrafico);
    this.buscaMesesDownload();
  },
  methods: {
    ...mapActions({
      adicionaDadosGraficoTreinamento:
        "usuario/adicionaDadosGraficoTreinamento",
    }),
    buscaMesesDownload() {
      obterMesesComTreinamento(
        this.arrayGrafico.ano,
        this.autorizadaAtiva
      ).then((resp) => {
        this.mesesFiltro = resp.data.sort();
      });
    },
    buscarTreinamentoPorUsuario() {
      this.buscarPerformanceTreinamentos(1);
      this.buscaFeita = true;
    },
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    filtroGrafico(filtro) {
      filtro.autorizadaId = parseInt(this.autorizadaAtiva);
      buscarGraficoTreinamento(filtro).then((resp) => {
        this.infoTreinamentos = resp.data.grafico;
        this.universidadeEncantamento = resp.data.grafico.resultados[0];
        this.escolaTecnica = resp.data.grafico.resultados[1];
        this.horaDoAdmin = resp.data.grafico.resultados[2];
      });

      this.arrayBusca.mes = filtro.mes;
      this.arrayBusca.ano = filtro.ano;
      this.buscarPerformanceTreinamentos(1);
    },
    buscarPerformanceTreinamentos(pag) {
      this.arrayBusca.status = this.statusFiltro;
      if (this.arrayBusca.buscar != null) {
        let cpf = JSON.stringify(this.arrayBusca.buscar);
        cpf = cpf.replace(/[^\d]+/g, "");
        cpf.length == 11 && !isNaN(cpf)
          ? (this.arrayBusca.buscarItem = cpf)
          : (this.arrayBusca.buscarItem = this.arrayBusca.buscar);
      }

      pag == 0 ? "" : (this.arrayBusca.pagina = pag);
      this.arrayBusca.autorizadaId = this.autorizadaAtiva;

      buscarTreinamentoEquipe(this.arrayBusca).then((resp) => {
        if (resp.data.treinamentos != null)
          this.treinamentos = resp.data.treinamentos;
        if (resp.data.paginacao != null) {
          this.totalobjetos = resp.data.paginacao.totalPaginas;
          this.ultimoItem = true;
        }
      });
    },
    limparBusca() {
      this.buscaFeita = false;
      this.arrayBusca.pagina = 1;
      this.arrayBusca.autorizadaId = "";
      this.arrayBusca.buscar = null;
      // mes: this.dataJs.getMonth() + 1,
      // ano: this.dataJs.getFullYear()
      this.buscarPerformanceTreinamentos(0);
    },
    pagAnterior() {
      this.ultimoItem = false;
      this.arrayBusca.pagina = this.arrayBusca.pagina - 1;
      buscarTreinamentoEquipe(this.arrayBusca).then((resp) => {
        this.treinamentos = resp.data.treinamentos;
      });
    },
    proxPagina() {
      if (this.arrayBusca.pagina < this.totalobjetos) {
        this.arrayBusca.pagina = this.arrayBusca.pagina + 1;
        this.buscarPerformanceTreinamentos(0);
      }
      if (this.arrayBusca.pagina == this.totalobjetos) {
        this.ultimoItem = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filtro {
  margin-top: 15px;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }
}

.titulo {
  font-size: 16px;
  font-weight: 600;
  width: 70%;
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    width: auto;
    font-size: 24px;
    margin-left: 30px;
    width: auto;
  }
}

.graficos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .grafico-circulo {
    width: 150px;

    @media (max-width: 768px) {
      width: auto;
    }
  }
}

.grafico {
  display: flex;
  flex-direction: column;
}

.programa {
  background-color: $azul-claro;
  // width: 100%;
  margin: 5px;
  padding: 15px;
  border-radius: 5px;

  .bg-grafico {
    width: 100%;
    height: 250px;

    @media (max-width: 768px) {
      height: 300px;
    }
  }
}

.big-numbers {
  width: 100%;
  background: #e9e9e9;
  border-radius: 10px;
  min-height: 210px;
  margin-top: 32px;
  margin-bottom: 46px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 25px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 12px 0;
  }

  .box {
    width: 200px;
    height: 116px;
    margin-right: 16px;
    background: #dbdbdb;
    border-radius: 10px;

    @media (max-width: 768px) {
      width: 80%;
      margin: 8px;
    }

    .titulo {
      font-size: 14px;
      color: #373737;
      margin-bottom: 0;
      font-weight: bold;
      padding-top: 10px;
      margin-left: 10px;
    }

    .numero {
      font-size: 49px;
      color: $laranja-escuro;
      text-align: center;
      margin-top: 15px;
      font-weight: bold;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}

.paginacao {
  display: flex;
  justify-content: center;
  margin: 0 auto 25px auto;
  padding-top: 5px;

  p {
    cursor: auto;
  }

  button {
    padding: 6px;
    background: none;
    border: none;
    color: $cinza-interno;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    outline: none;
    transition: 0.3s;

    &:disabled {
      cursor: default;
    }
  }
}

.treinamentos {
  background-color: $azul-claro;
  margin: 5px;
  padding: 15px 0;
  border-radius: 5px;
  position: relative;

  .titulo {
    margin-bottom: 0;
  }

  .busca-treinamento {
    width: 370px;
    text-align: right;

    @media (max-width: 768px) {
      margin: 16px auto 0 auto;
      width: 100%;
    }

    .btn-input {
      width: 100%;
      max-width: 350px;
      display: flex;
      justify-content: center;
      position: relative;
      margin: auto;
    }

    .input-buscar {
      border: 0;
      border-radius: 5px;
      background: $branco;
      height: 40px;
      padding: 0 10px;

      @media (max-width: 768px) {
        width: 300px;
        margin: 0 auto;
        background: $cinza-claro;
      }
    }

    .btn-buscar {
      color: $grafite;
      height: 40px;
      text-align: center;
      background-color: $neutro6;
      font-size: 14px;
      width: 100px;
      border-radius: 5px;
      margin-left: 10px;
    }

    .btn-buscar-mobile {
      width: 40px;
      position: absolute;
      right: 8%;
      top: 8px;
      background: $cinza-claro;
    }
  }

  .icone-treinamentos {
    margin-left: 20px;
  }

  .barra-titulo {
    background: $dark-cinza;
    margin-top: 20px;
    margin-bottom: 0px;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: $branco;
    padding: 0 15px;
    position: relative;
  }

  .status {
    min-width: 100px;
  }

  .icone-filtro {
    width: 25px;
    position: absolute;
    right: 15px;
    top: 12px;
    cursor: pointer;

    @media (max-width: 768px) {
      right: 0;
    }
  }

  .cpf {
    width: 16%;
  }

  .nome {
    width: 30%;
  }

  .curso {
    width: 30%;
  }

  .data {
    width: 15%;
  }

  .item {
    background: $neutro6;
    margin: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;

    .icone {
      background: $bg-verde;
      min-width: 90px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      font-weight: bold;
      font-size: 12px;
      color: $branco;
      row-gap: 10px;
    }

    .texto {
      padding: 10px;
      font-size: 12px;
      font-weight: 600;
      width: 100%;

      @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
      }

      .pontos-data {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;

        @media (min-width: 768px) {
          width: 45%;
          margin-top: 0px;
        }
      }
    }
  }

  .btn {
    background: $bg-btn-padrao;
    color: $branco;
    margin-top: 20px;

    &:disabled {
      opacity: 0.6;
    }

    @media (min-width: 768px) {
      width: 250px;
      margin-left: auto;
    }
  }

  .h1 {
    text-align: center;
    font-size: 16px;
    margin: revert;
    margin: 60px 0;
    font-weight: revert;
    text-transform: uppercase;
    opacity: 0.6;
  }

  .h2 {
    text-align: center;
    margin: 20px 0;
  }
}

.legenda {
  display: flex;
  width: 100%;
  margin: auto;
  font-size: 10px;
  margin-top: 30px;
  height: 60px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    justify-content: start;
    height: auto;
  }

  .pontuou {
    display: flex;
    align-items: center;
    margin-right: 30px;

    @media (min-width: 768px) {
      margin-right: 20px;
    }

    .cor {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }

    .linha {
      height: 4px;
      width: 15px;
    }

    .reprovado {
      background-color: #cacaca;
      font-size: 14px;
      color: #fff;
    }

    .texto {
      font-size: 10px;
      white-space: nowrap;

      @media (min-width: 768px) {
        font-size: 12px;
      }
    }
  }
}
</style>