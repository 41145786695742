<template>
  <div class="home">
    <Header :headerGrande="true" :exibeAutorizadas="true" :pagHome="true" />
    <div class="home__components">
      <PerfilUsuario />
      <div class="home__itens">
        <Carrousel />
        <TreinamentosCarrousel />
        <CampanhasAtivasCarrousel />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { Header, Footer } from '@/components/elementos'
import PerfilUsuario from '@/components/elementos/home/PerfilUsuario.vue'
import Carrousel from '@/components/elementos/home/Carrousel.vue'
import TreinamentosCarrousel from '@/components/elementos/home/TreinamentosCarrousel.vue'
import CampanhasAtivasCarrousel from '@/components/elementos/home/CampanhasAtivasCarrousel.vue'
import { mapState, mapActions } from 'vuex'
export default {
  name: "Home",

  components: {
    Header,
    Footer,
    PerfilUsuario,
    Carrousel,
    CampanhasAtivasCarrousel,
    TreinamentosCarrousel,
  },

  computed: {
    ...mapState({
      modalAnuncioStatusUsuario: state => state.usuario.modalAnuncioStatusUsuario,
    })
  },

  data() {
    return {
      modalNovidade: true
    }
  },

  mounted() {
    if (localStorage.getItem('verModalNovidade') && localStorage.getItem('verModalNovidade') == 1) {
      this.defineAnuncioStatusUsuario(0);
    } else {
      this.defineAnuncioStatusUsuario(1)
    }

  },

  methods: {
    ...mapActions({
      defineAnuncioStatusUsuario: "usuario/defineAnuncioStatusUsuario",
    })
  }
}
</script>

<style lang="scss" scoped>
.home {


  &__components {

    @media(min-width: 768px) {
      width: 1200px;
      margin: 1rem auto;
      display: flex;
      justify-content: center;
      margin-top: 150px;
    }
  }
}
</style>