<template>
    <div>
        <div class="grafico">
            <div class="programa">
                <div class="filtroContainer">
                    <img :src="iconesImgs.iconePrograma">
                    <h2 class="titulo">{{ titulo }}</h2>
                    <div class="filtro">
                        <filtrar-desempenho 
                            :filtrarGrafico="(filtro) => filtroGrafico(filtro)" 
                            :mostrarTitulo="false"
                            :tipo="true"
                        />
                    </div>
                </div>
                <div class="bg-grafico">
                    <Grafico :graficoLista="graficoLista" :options="options" :series="series" type="line" height="350"
                        v-if="mostraGrafico" />
                </div>
                <div class="legenda" v-if="mostraGrafico">
                    <div class="pontuou" v-for="(legenda, index) in graficoLista.legendas" :key="index">
                        <div :class="legenda.descricao == 'Pesquisas' ? 'cor linha' : 'cor'"
                            :style="{ backgroundColor: legenda.codigoCor }"></div>
                        <div class="texto">{{ legenda.descricao }}</div>
                    </div>
                    <div class="pontuou">
                        <div class="cor linha meta"></div>
                        <div class="texto">Meta</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { buscarGrafico } from "@/api/performance";
import { mapState } from "vuex";
import { buscarGraficoDesempenho } from "@/api/performance";
import iconesImgs from "@/utils/icones.js";
import FiltrarDesempenho from "../Filtros/FiltroDesempenhoTitular.vue";
import VueApexCharts from 'vue-apexcharts';
// import moment from "moment";


export default {
    components: {
        Grafico: VueApexCharts,
        FiltrarDesempenho,
    },
    props: ['titulo'],

    computed: {
        ...mapState({
            autorizadaAtiva: state => state.usuario.autorizadaAtiva,
            cargoId: state => state.usuario.cargoUsuario
        })
    },

    data() {
        return {
            iconesImgs,
            mostraGrafico: false,
            treinamentos: [],
            graficoLista: [],
            validarLegenda: null,
            arrayGrafico: {
                autorizadaId: '',
                origemkpiId: 1,
                mes: 0,
                ano: 2024
            },
            series: [
                {
                    name: 'Não pontuou',
                    type: 'column',
                    data: [],
                    color: '#CACACA'
                },
                {
                    name: 'Pontuou',
                    type: 'column',
                    data: [],
                    color: '#A2E323'
                },
                {
                    name: 'Pesquisas',
                    type: 'line',
                    data: [],
                    color: '#f58420'
                },
                {
                    name: 'Meta',
                    data: [],
                    type: 'area',
                    color: '#7f53a2'
                },
            ],
            options: {
                legend: {
                    show: false
                },
                chart: {
                    height: '400px',
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    },
                    background: '#E9E9E9'
                },
                fill: {
                    type: ['', '', '', 'gradient'],
                    gradient: {
                        shade: 'dark',
                        type: "horizontal",
                        opacityFrom: 0,
                        opacityTo: 0,
                    }
                },
                stroke: {
                    show: true,
                    width: [0, 0, 3, 3],
                    curve: 'straight',
                    style: {
                        colors: '#000'
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ['#F7941D'],
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                    },
                    formatter: function (val, opts) {

                        let tipoKpi = sessionStorage.getItem('tipoKpi');
                        let contador = parseInt(sessionStorage.getItem('contador'))

                        if (contador < 3) {
                            sessionStorage.setItem('contador', contador + 1)
                            return
                        }


                        if (val === null) return;

                        // if (sessionStorage.getItem('metaUsuario') == val) return;

                        if (tipoKpi == "5 Estrelas" || tipoKpi == "TLS" || tipoKpi == "TAE") return val;

                        if (opts.ctx) return val;

                        return val + '%';
                    },
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            position: 'bottom'
                        }
                    },
                    area: {
                        enabled: false,
                        dataLabels: {

                        },
                    },
                },
                labels: [],
                yaxis: [
                    {
                        max: null,
                        tickAmount: null,
                        title: {
                            text: 'FCC',
                        },
                    },
                    {
                        opposite: true,
                        show: false,
                        max: 100,
                        tickAmount: 1,
                        title: {
                            text: 'Pesquisas'
                        }
                    },
                    {
                        opposite: true,
                        max: 0,
                        tickAmount: 0,
                        title: {
                            text: ''
                        },
                    },
                    {
                        opposite: true,
                        show: false,
                        max: 100,
                        tickAmount: 1,
                        title: {
                            text: 'Meta'
                        },
                    },
                ],
                tooltip: {
                    enabled: false
                },
            },
        }
    },
    mounted() {
        this.arrayGrafico.mes = new Date().getMonth() + 1;
        this.filtroGrafico(this.arrayGrafico);
    },
    watch: {
        autorizadaAtiva() {
            this.filtroGrafico(this.arrayGrafico);
        }
    },
    methods: {
        filtroGrafico(filtro) {
            this.mostraGrafico = false;
            filtro.autorizadaId = null;
            if (this.cargoId == 8 || this.cargoId == 9 || this.cargoId == 10) {
                filtro.autorizadaId = parseInt(this.autorizadaAtiva);
            }
            buscarGraficoDesempenho(filtro).then(resp => {
                if (resp.data.grafico == null) return
                this.labels = [];
                this.graficoLista = resp.data.grafico
                let escalasPerformance = this.graficoLista.escalasKpi;
                let escalasDireita = this.graficoLista.escalasPesquisa;
                this.validarLegenda = filtro.kpi;

                sessionStorage.setItem('metaUsuario', this.graficoLista.metaValor)
                sessionStorage.setItem('tipoKpi', this.graficoLista.escalasKpiDescricao)
                sessionStorage.setItem('contador', 0)

                let meses = [], temPonto = [], naoTemPonto = [], pesquisas = [], metaUsuario = [];

                this.graficoLista.meses.forEach((item, index) => {
                    meses[index] = item.mes;
                    pesquisas[index] = item.qtdPesquisa == 0 ? null : item.qtdPesquisa;
                    metaUsuario[index] = this.graficoLista.metaValor;

                    if (item.codigoCor == '#A2E323') {
                        temPonto[index] = item.valorPerformance == this.graficoLista.metaValor ? item.valorPerformance : item.valorPerformance;
                        naoTemPonto[index] = 0;
                    }

                    if (item.codigoCor == '#CACACA') {
                        naoTemPonto[index] = item.valorPerformance == this.graficoLista.metaValor ? item.valorPerformance : item.valorPerformance;
                        temPonto[index] = 0;
                    }
                });

                this.options.yaxis[0].max = escalasPerformance[escalasPerformance.length - 1];
                this.options.yaxis[0].tickAmount = escalasPerformance.length - 1;

                this.options.yaxis[1].max = escalasPerformance[escalasPerformance.length - 1];
                this.options.yaxis[1].tickAmount = escalasPerformance.length - 1;

                this.options.yaxis[2].max = escalasDireita[escalasDireita.length - 1];
                this.options.yaxis[2].tickAmount = escalasDireita.length - 1;

                this.options.yaxis[3].max = escalasPerformance[escalasPerformance.length - 1];
                this.options.yaxis[3].tickAmount = escalasPerformance.length - 1;

                this.options.yaxis[2].title.text = this.graficoLista.escalasPesquisaDescricao;
                this.options.yaxis[0].title.text = this.graficoLista.escalasKpiDescricao;

                this.series[0].data = naoTemPonto;
                this.series[1].data = temPonto;
                this.series[2].data = pesquisas;
                this.series[3].data = metaUsuario;
                this.options.labels = meses;

                this.mostraGrafico = true;
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.filtro {
    margin-top: 15px;
    width: 100%;

    @media(min-width: 768px) {
        display: flex;
        justify-content: flex-end;
    }
}

.paginacao {
    display: flex;
    justify-content: center;
    margin: 0 auto 25px auto;
    padding-top: 5px;

    button {
        padding: 6px;
        background: none;
        border: none;
        color: $cinza-interno;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
        outline: none;
        transition: 0.3s;

        &:disabled {
            cursor: default;
        }
    }
}

.grafico {
    display: flex;
    flex-direction: column;
}

.treinamentos,
.programa {
    background-color: $azul-claro;
    margin: 5px auto;
    padding: 15px;
    border-radius: 5px;
    max-width: 330px;

    @media(min-width: 768px) {
        width: 100%;
        max-width: 782px;
        margin-top: 10px;
    }

    .titulo {
        font-size: 16px;
        font-weight: 600;
        width: 150px;
        display: inline-block;
        margin-left: 15px;
        margin-bottom: 20px;

        @media(min-width: 768px) {
            width: auto;
            font-size: 24px;
            margin-bottom: 40px;
            margin-left: 30px;
        }
    }

    .bg-grafico {
        background-color: #E9E9E9;
        width: 100%;
        height: 430px;
        margin-top: 15px;
        padding-top: 40px;
    }

    .legenda {
        display: flex;
        width: 100%;
        margin: auto;
        font-size: 10px;
        margin-top: 30px;
        height: 60px;
        flex-wrap: wrap;

        @media(min-width: 768px) {
            justify-content: start;
            height: auto;
        }

        .pontuou {
            display: flex;
            align-items: center;
            margin-right: 30px;

            @media(min-width: 768px) {
                margin-right: 20px;
            }

            .cor {
                width: 12px;
                height: 12px;
                margin-right: 5px;
            }

            .linha {
                height: 4px;
                width: 15px;
            }

            .meta {
                background-color: #7F53A2;
            }

            .texto {
                font-size: 10px;
                white-space: nowrap;

                @media(min-width: 768px) {
                    font-size: 12px;
                }
            }

        }

        .nao-pontuou {
            display: flex;
            align-items: center;

            @media(min-width: 768px) {
                margin-right: 20px;
            }

            .cor {
                width: 12px;
                height: 12px;
                margin-right: 5px;
            }
        }

        .pesquisas {
            display: flex;
            align-items: center;

            .cor {
                width: 12px;
                height: 12px;
                margin-right: 5px;
            }
        }
    }
}
</style>